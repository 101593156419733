import React from 'react';
import './header.css';
import Categories from "../Categories";
import { Link } from "react-router-dom";

const HeaderSection = () => {
  return (
    <div className="header-block">
      <div className="header-wrapper">
        <a variant="base" className="header-logo" href="/">
          <div className="logo-wrapper" data-testid="logo" width="auto">
            Advising Buddy
          </div>
        </a>
        <ul className="item-list" width="auto">
          <li className="category-item">
            <a variant="base" id="header-menu-item-0" className="sc-a66dc63f-0 eHbBhO" >Categories</a>
            {/* {Object.keys(Categories).map((category, index) => {
              return (
                <li key={index}>
                  <a className="dropdown-item" href="#">
                    {category} &raquo;
                  </a>
                  <ul className="dropdown-menu dropdown-submenu">
                    {Categories[category].map((json, index) => {
                      return (
                        <li>
                          <Link
                            to={`/${json.username}`}
                            className="dropdown-item"
                            href="#"
                          >
                            {json.name}
                          </Link>
                        </li>
                      );
                    })}
                  </ul>
                </li>
              );
            })} */}
          </li>
          <li className="sc-b65b1a42-1 cqarPa arrow">
            <a variant="base" id="header-menu-item-1" className="sc-a66dc63f-0 eHbBhO" href="/solutions">
              Pricing
            </a>
          </li>
          <li className='search-bar-wrapper'>
            <div className='search-bar'>
              <input type='text' placeholder='Search' />
              <div className='search-button'><i class="fa fa-search text-white"></i></div>
            </div>
          </li>
          <li className="skip">
            <a className="login-button" data-testid="login-header" href="/signin">
              <span className="">Log In</span></a>
          </li>
          <li className="skip">
            <a data-testid="signup-button" href="/signup">
              <span className="signup-button">Sign Up</span></a>
          </li>
        </ul>
      </div>
    </div>
  )
}

export default HeaderSection;