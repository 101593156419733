import React, { useEffect, useState } from "react";
import "./HomePage.css";
import homeBanner1on1Call from "../../Utility/images/homepageImage/1on1Call.png";
import homepageBanner from "../../Utility/images/homepageImage/call_management_banner.png";
import homeBannermonitizeTime from "../../Utility/images/homepageImage/monetizeYourTime.png";
import HeaderSection from "./header";
import { toast, ToastContainer } from "react-toastify";
import axios from "axios";
import dataStored from "../../Utility/DataStoring/dataStored";
import Carousel from 'react-bootstrap/Carousel';

const urls = require("../../Utility/URL/url.js");


function HomePage() {

  const requestBody = {}
  const headers = {};


  useEffect(() => {
    fetch('https://api.ipify.org?format=json')
      .then(response => response.json())
      .then(data => {
        headers['X-Forwarded-For'] = data.ip;
        axios
          .post(urls.POST_USER_ON_LANDINGPAGE, requestBody, { headers })
          .then((res) => {
            if (res.status === 200) {
              const value = res.data;
              // dataStored('dashboard', value);
            }
          })
          .catch(error => console.log(error))
      })
      .catch(error => console.log(error))
  }, [])
  return (
    <React.Fragment>
      <HeaderSection />

      {/* hero section */}
      <section id="hero" className="d-flex align-items-center justify-content-center">
        <div className="container" id="dashboard">
          <Carousel >
            <Carousel.Item>
              <div className="row">
                <div
                  className="col-lg-6 d-flex flex-column justify-content-center pt-4 pt-lg-0 order-2 order-2 order-lg-1"
                  data-aos="fade-up"
                  data-aos-delay="200"
                >
                  <div className="headingDashboard">Transform your <br /><b>time into earnings</b></div>
                  <div className="subheadingDashboard">Schedule personalized video calls with your <br />audience and turn your skills into income.</div>
                  <div className="loginBtn ">Get Started &nbsp;	<i className='fas text-white'>&#xf061;</i></div>
                </div>

                <div
                  className="col-lg-6 order-1 order-lg-2 hero-img d-flex justify-content-center"
                  data-aos="zoom-in"
                  data-aos-delay="200"
                >
                  <img
                    src={homeBannermonitizeTime}
                    className="dashboardImage elementAnimation"
                    alt="bannerImg"
                  />
                </div>
              </div>
            </Carousel.Item>
            <Carousel.Item>
              <div className="row">
                <div
                  className="col-lg-6 d-flex flex-column justify-content-center pt-4 pt-lg-0 order-2 order-2 order-lg-1"
                  data-aos="fade-up"
                  data-aos-delay="200"
                >
                  <div className="headingDashboard">Monitize your <b>expertise <br />with 1:1 calls</b></div>
                  <div className="subheadingDashboard">Interact directly with your followers <br />and get paid for your time.</div>
                  <div className="loginBtn ">Claim your free profile &nbsp;	<i className='fas text-white'>&#xf061;</i></div>
                </div>

                <div
                  className="col-lg-6 order-1 order-lg-2 hero-img d-flex justify-content-center"
                  data-aos="zoom-in"
                  data-aos-delay="200"
                >
                  <img
                    src={homeBanner1on1Call}
                    className="dashboardImage elementAnimation"
                    alt="bannerImg"
                  />
                </div>
              </div>
            </Carousel.Item>
            <Carousel.Item>
              <div className="row">
                <div
                  className="col-lg-6 d-flex flex-column justify-content-center pt-4 pt-lg-0 order-2 order-2 order-lg-1"
                  data-aos="fade-up"
                  data-aos-delay="200"
                >
                  <div className="headingDashboard">Connect, <b>Share, <br />Earn</b></div>
                  <div className="subheadingDashboard">Your time is valuable—start earning <br />by sharing your knowledge.</div>
                  <div className="loginBtn ">Join Now &nbsp;	<i className='fas text-white'>&#xf061;</i></div>
                </div>

                <div
                  className="col-lg-6 order-1 order-lg-2 hero-img d-flex justify-content-center"
                  data-aos="zoom-in"
                  data-aos-delay="200"
                >
                  <img
                    src={homepageBanner}
                    className="dashboardImage elementAnimation"
                    alt="bannerImg"
                  />
                </div>
              </div>
            </Carousel.Item>
          </Carousel>
        </div>
      </section>
      {/* End hero section*/}

      {/* <!-- ======= Feature Section ======= --> */}
      <section id="feature" className="section services section-bg">
        <div className="container" data-aos="fade-up">
          <div className="section-title">
            <h2>Features</h2>
          </div>
          <div className="row">
            <div
              className="col-lg-6 d-flex flex-column justify-content-center pt-4 pt-lg-0 order-2 order-2 order-lg-1"
              data-aos="fade-up"
              data-aos-delay="200"
            >
              <div className="featuresHeading">Mainfeatures of <br />Adivising Buddy</div>
              <div className="featuresSubHeading">Here are some key features that distinguish <br />Advising Buddy from other applications.</div>
            </div>

            <div
              className="col-lg-6 order-1 order-lg-2 hero-img"
              data-aos="zoom-in"
              data-aos-delay="200"
            >
              <img
                src={homeBannermonitizeTime}
                className="dashboardImage"
                alt="bannerImg"
                width='300px'

              />
            </div>
          </div>

          <div className="row">
            <div
              className="col-xl-3 col-md-6 d-flex align-items-stretch"
            >
              <div className="icon-box">
                <div className="icon">
                  <i className="bx bxl-dribbble"></i>
                </div>
                <h4>
                  1:1 Video Call
                </h4>
                <p>
                  Advisor and User can interact with the seamless feature of
                  video calling option available
                </p>
              </div>
            </div>
            <div
              className="col-xl-3 col-md-6 d-flex align-items-stretch"
            >
              <div className="icon-box">
                <div className="icon">
                  <i className="bx bxl-dribbble"></i>
                </div>
                <h4>
                  1:1 Video Call
                </h4>
                <p>
                  Advisor and User can interact with the seamless feature of
                  video calling option available
                </p>
              </div>
            </div>
            <div
              className="col-xl-3 col-md-6 d-flex align-items-stretch"
            >
              <div className="icon-box">
                <div className="icon">
                  <i className="bx bxl-dribbble"></i>
                </div>
                <h4>
                  1:1 Video Call
                </h4>
                <p>
                  Advisor and User can interact with the seamless feature of
                  video calling option available
                </p>
              </div>
            </div>
            <div
              className="col-xl-3 col-md-6 d-flex align-items-stretch"
            >
              <div className="icon-box">
                <div className="icon">
                  <i className="bx bxl-dribbble"></i>
                </div>
                <h4>
                  1:1 Video Call
                </h4>
                <p>
                  Advisor and User can interact with the seamless feature of
                  video calling option available
                </p>
              </div>
            </div>
            <div
              className="col-xl-3 col-md-6 d-flex align-items-stretch"
            >
              <div className="icon-box">
                <div className="icon">
                  <i className="bx bxl-dribbble"></i>
                </div>
                <h4>
                  1:1 Video Call
                </h4>
                <p>
                  Advisor and User can interact with the seamless feature of
                  video calling option available
                </p>
              </div>
            </div>
            <div
              className="col-xl-3 col-md-6 d-flex align-items-stretch"
            >
              <div className="icon-box">
                <div className="icon">
                  <i className="bx bxl-dribbble"></i>
                </div>
                <h4>
                  1:1 Video Call
                </h4>
                <p>
                  Advisor and User can interact with the seamless feature of
                  video calling option available
                </p>
              </div>
            </div>
            <div
              className="col-xl-3 col-md-6 d-flex align-items-stretch"
            >
              <div className="icon-box">
                <div className="icon">
                  <i className="bx bxl-dribbble"></i>
                </div>
                <h4>
                  1:1 Video Call
                </h4>
                <p>
                  Advisor and User can interact with the seamless feature of
                  video calling option available
                </p>
              </div>
            </div>
            <div
              className="col-xl-3 col-md-6 d-flex align-items-stretch"
            >
              <div className="icon-box">
                <div className="icon">
                  <i className="bx bxl-dribbble"></i>
                </div>
                <h4>
                  1:1 Video Call
                </h4>
                <p>
                  Advisor and User can interact with the seamless feature of
                  video calling option available
                </p>
              </div>
            </div>
          </div>
        </div>
      </section>
      {/* <!-- End Services Section --> */}
      {/* <!-- ======= About Us Section ======= --> */}
      <section id="about" className="about section">
        <div className="container" data-aos="fade-up">
          <div className="section-title">
            <h2>About Us</h2>
          </div>

          <div className="row content">
            <div className="col-lg-6">
              <p>
                Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
                eiusmod tempor incididunt ut labore et dolore magna aliqua.
              </p>
              <ul>
                <li>
                  <i className="ri-check-double-line"></i> Ullamco laboris nisi ut
                  aliquip ex ea commodo consequat
                </li>
                <li>
                  <i className="ri-check-double-line"></i> Duis aute irure dolor in
                  reprehenderit in voluptate velit
                </li>
                <li>
                  <i className="ri-check-double-line"></i> Ullamco laboris nisi ut
                  aliquip ex ea commodo consequat
                </li>
              </ul>
            </div>
            <div className="col-lg-6 pt-4 pt-lg-0">
              <p>
                Ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis
                aute irure dolor in reprehenderit in voluptate velit esse cillum
                dolore eu fugiat nulla pariatur. Excepteur sint occaecat
                cupidatat non proident, sunt in culpa qui officia deserunt
                mollit anim id est laborum.
              </p>
              <a href="#" className="btn-learn-more">
                Learn More
              </a>
            </div>
          </div>
        </div>
        <ToastContainer />
      </section>
      {/* <!-- End About Us Section --> */}
      <div className="footer"></div>
    </React.Fragment>
  );
}

export default HomePage;
