module.exports = {
  ONE_ON_ONE_CALL: "ONE_ON_ONE_CALL",
  WEBINAR: "WEBINAR",
  TEXT_QUERY: "TEXT_QUERY",
  RAZORPAY_key: "rzp_test_yq5te40VGqTPOW",
  COMPANY_NAME: "ADVISING BUDDY",
  CANCELED_BY_USER: "CANCELED_BY_USER",
  COMPANY_LOGO: "https://example.com/your_logo",
  REACH_OUT_TO_ME_DEFAULT:
    "Hey! Just created my profile on AdvisingBuddy.com stay tuned for more update",
  PAYMENT_RESPONSE_STATUS_SUCCESS: "SUCCESS",
  PAYMENT_RESPONSE_STATUS_FAIL: "FAIL",
  PAYMENT_RESPONSE_STATUS_ERROR: "ERROR",
  PAYMENT_STATUS_PAID: "PAID",
  PAYMENT_STATUS_FAILED: "FAILED",
  PAYMENT_STATUS_FREE: "FREE",

  SOMETHING_WENT_WRONG: "Something Went Wrong , Please try again later!",

  ALL_TRANSACTIONS: "All transactions",

  WITHDRAWAL: "withdrawal",

  SERVICE_BOOKINGS: "Service Booking",

  ABD_SESSION: "abd_session",
};
